<template>
  <div>
    <!-- 评论框 -->
    <div style="margin-bottom: 40px">
      <div class="comment-head">
        <i class="el-icon-edit-outline" style="font-weight: bold;font-size: 22px;"></i> 留言
      </div>
      <div>
        <!-- 文字评论 -->
        <div v-show="!isGraffiti">
          <commentBox @showGraffiti="isGraffiti = !isGraffiti"
                      @submitComment="submitComment">
          </commentBox>
        </div>
        <!-- 画笔 -->
        <!--        <div v-show="isGraffiti">-->
        <!--          <graffiti @showComment="isGraffiti = !isGraffiti"-->
        <!--                    @addGraffitiComment="addGraffitiComment">-->
        <!--          </graffiti>-->
        <!--        </div>-->
      </div>
    </div>

    <!-- 评论内容 -->
    <div v-if="comments.length > 0">
      <!-- 评论数量 -->
      <div class="commentInfo-title">
        <span style="font-size: 1.15rem">Comments | </span>
        <span>{{ total }} 条留言</span>
      </div>
      <!-- 评论详情 -->
      <div id="comment-content" class="commentInfo-detail"
           v-for="(item, index) in comments"
           :key="index">
        <!-- 头像 -->
        <el-avatar shape="square" class="commentInfo-avatar" :size="35" :src="item.avatar"></el-avatar>

        <div style="flex: 1;padding-left: 12px">
          <!-- 评论信息 -->
          <div style="display: flex;justify-content: space-between">
            <div>
              <span class="commentInfo-username">{{ item.username }}</span>
              <span class="commentInfo-master" v-if="item.userId === userId">主人翁</span>
              <span class="commentInfo-other">{{ $common.getDateDiff(item.createTime) }}</span>
            </div>
            <div class="commentInfo-reply" @click="replyDialog(item, item)">
              <span v-if="item.childComments.total > 0">{{ item.childComments.total }} </span><span>回复</span>
            </div>
          </div>
          <!-- 评论内容 -->
          <div class="commentInfo-content">
            <span v-html="item.commentContent"></span>
          </div>
          <!-- 回复模块 -->
          <div v-if="!$common.isEmpty(item.childComments) && !$common.isEmpty(item.childComments.records)">
            <div class="commentInfo-detail" v-for="(childItem, i) in item.childComments.records" :key="i">
              <!-- 头像 -->
              <el-avatar shape="square" class="commentInfo-avatar" :size="30" :src="childItem.avatar"></el-avatar>

              <div style="flex: 1;padding-left: 12px">
                <!-- 评论信息 -->
                <div style="display: flex;justify-content: space-between">
                  <div>
                    <span class="commentInfo-username-small">{{ childItem.username }}</span>
                    <span class="commentInfo-master" v-if="childItem.userId === userId">主人翁</span>
                    <span class="commentInfo-other">{{ $common.getDateDiff(childItem.createTime) }}</span>
                  </div>
                  <div>
                    <span class="commentInfo-reply" @click="replyDialog(childItem, item)">回复</span>
                  </div>
                </div>
                <!-- 评论内容 -->
                <div class="commentInfo-content">
                  <template v-if="childItem.parentCommentId !== item.id &&
                                  childItem.parentUserId !== childItem.userId">
                    <span style="color: var(--blue)">@{{ childItem.parentUsername }} </span>:
                  </template>
                  <span v-html="childItem.commentContent"></span>
                </div>
              </div>
            </div>
            <!-- 分页 -->
            <div class="pagination-wrap" v-if="item.childComments.records.length < item.childComments.total">
              <div class="pagination"
                   @click="toChildPage(item)">
                展开
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <proPage :current="pagination.current"
               :size="pagination.size"
               :total="pagination.total"
               :buttonSize="6"
               :color="$constant.commentPageColor"
               @toPage="toPage">
      </proPage>
    </div>

    <div v-else class="myCenter" style="color: var(--greyFont)">
      <i>来发第一个留言啦~</i>
    </div>

    <el-dialog title="留言"
               :visible.sync="replyDialogVisible"
               width="30%"
               :before-close="handleClose"
               :append-to-body="true"
               destroy-on-close
               center>
      <div>
        <commentBox :disableGraffiti="true"
                    @submitComment="submitReply">
        </commentBox>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// const graffiti = () => import( "./graffiti");
const commentBox = () => import( "./commentBox");
const proPage = () => import( "../common/proPage");

export default {
  components: {
    // graffiti,
    commentBox,
    proPage
  },
  props: {
    source: {
      type: Number
    },
    type: {
      type: String
    },
    userId: {
      type: Number
    }
  },
  data() {
    return {
      isGraffiti: false,
      total: 0,
      replyDialogVisible: false,
      floorComment: {},
      replyComment: {},
      comments: [],
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        source: this.source,
        commentType: this.type,
        floorCommentId: null
      }
    };
  },

  computed: {},

  created() {
    this.getComments(this.pagination);
    this.getTotal();
  },
  methods: {
    toPage(page) {
      this.pagination.current = page;
      window.scrollTo({
        top: document.getElementById('comment-content').offsetTop
      });
      this.getComments(this.pagination);
    },
    getTotal() {
      this.$http.get(this.$constant.baseURL + "/comment/getCommentCount", {source: this.source, type: this.type})
        .then((res) => {
          if (!this.$common.isEmpty(res.data)) {
            this.total = res.data;
          }
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
    },
    toChildPage(floorComment) {
      floorComment.childComments.current += 1;
      let pagination = {
        current: floorComment.childComments.current,
        size: 5,
        total: 0,
        source: this.source,
        commentType: this.type,
        floorCommentId: floorComment.id
      }
      this.getComments(pagination, floorComment, true);
    },
    emoji(comments, flag) {
      comments.forEach(c => {
        c.commentContent = c.commentContent.replace(/\n/g, '<br/>');
        c.commentContent = this.$common.faceReg(c.commentContent);
        c.commentContent = this.$common.pictureReg(c.commentContent);
        if (flag) {
          if (!this.$common.isEmpty(c.childComments) && !this.$common.isEmpty(c.childComments.records)) {
            c.childComments.records.forEach(cc => {
              c.commentContent = c.commentContent.replace(/\n/g, '<br/>');
              cc.commentContent = this.$common.faceReg(cc.commentContent);
              cc.commentContent = this.$common.pictureReg(cc.commentContent);
            });
          }
        }
      });
    },
    getComments(pagination, floorComment = {}, isToPage = false) {
      this.$http.post(this.$constant.baseURL + "/comment/listComment", pagination)
        .then((res) => {
          if (!this.$common.isEmpty(res.data) && !this.$common.isEmpty(res.data.records)) {
            if (this.$common.isEmpty(floorComment)) {
              this.comments = res.data.records;
              pagination.total = res.data.total;
              this.emoji(this.comments, true);
            } else {
              if (isToPage === false) {
                floorComment.childComments = res.data;
              } else {
                floorComment.childComments.total = res.data.total;
                floorComment.childComments.records = floorComment.childComments.records.concat(res.data.records);
              }
              this.emoji(floorComment.childComments.records, false);
            }
          }
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
    },
    addGraffitiComment(graffitiComment) {
      this.submitComment(graffitiComment);
    },
    submitComment(commentContent) {
      let comment = {
        source: this.source,
        type: this.type,
        commentContent: commentContent
      };

      this.$http.post(this.$constant.baseURL + "/comment/saveComment", comment)
        .then((res) => {
          this.$message({
            type: 'success',
            message: '保存成功！'
          });
          this.pagination = {
            current: 1,
            size: 10,
            total: 0,
            source: this.source,
            commentType: this.type,
            floorCommentId: null
          }
          this.getComments(this.pagination);
          this.getTotal();
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
    },
    submitReply(commentContent) {
      let comment = {
        source: this.source,
        type: this.type,
        floorCommentId: this.floorComment.id,
        commentContent: commentContent,
        parentCommentId: this.replyComment.id,
        parentUserId: this.replyComment.userId
      };

      let floorComment = this.floorComment;

      this.$http.post(this.$constant.baseURL + "/comment/saveComment", comment)
        .then((res) => {
          let pagination = {
            current: 1,
            size: 5,
            total: 0,
            source: this.source,
            commentType: this.type,
            floorCommentId: floorComment.id
          }
          this.getComments(pagination, floorComment);
          this.getTotal();
        })
        .catch((error) => {
          this.$message({
            message: error.message,
            type: "error"
          });
        });
      this.handleClose();
    },
    replyDialog(comment, floorComment) {
      this.replyComment = comment;
      this.floorComment = floorComment;
      this.replyDialogVisible = true;
    },
    handleClose() {
      this.replyDialogVisible = false;
      this.floorComment = {};
      this.replyComment = {};
    }
  }
}
</script>

<style scoped>

.comment-head {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin: 40px 0 20px 0;
  user-select: none;
  color: var(--themeBackground);
}

.commentInfo-title {
  margin-bottom: 20px;
  color: var(--greyFont);
  user-select: none;
}

.commentInfo-detail {
  display: flex;
}

.commentInfo-avatar {
  border-radius: 5px;
}

.commentInfo-username {
  color: var(--orangeRed);
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
}

.commentInfo-username-small {
  color: var(--orangeRed);
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
}

.commentInfo-master {
  color: var(--green);
  border: 1px solid var(--green);
  border-radius: 0.2rem;
  font-size: 12px;
  padding: 2px 4px;
  margin-right: 5px;
}

.commentInfo-other {
  font-size: 12px;
  color: var(--greyFont);
  user-select: none;
}

.commentInfo-reply {
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  color: var(--white);
  background: var(--themeBackground);
  border-radius: 0.2rem;
  padding: 3px 6px;
}

.commentInfo-content {
  margin: 15px 0 25px;
  padding: 18px 20px;
  background: var(--commentContent);
  border-radius: 12px;
  color: var(--black);
  word-break: break-word;
}

.pagination-wrap {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.pagination {
  padding: 6px 20px;
  border: 1px solid var(--lightGray);
  border-radius: 3rem;
  color: var(--greyFont);
  user-select: none;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.pagination:hover {
  border: 1px solid var(--themeBackground);
  color: var(--themeBackground);
  box-shadow: 0 0 5px var(--themeBackground);
}
</style>
